<template>
  <div class="card">
    <div class="card-body">
      <receipts/>
    </div>
  </div>
</template>
<script>
import Receipts from '@/components/Account/Receipts/Receipts.vue'

export default {
  components: { Receipts }
}
</script>
