<template>
  <div>
    <div class="loader" v-if="loading">
      <base-loader-dual-ring />
    </div>
    <div class="receipts" v-if="invoices.length > 0 && !loading">
      <div class="receipts-title" style="font-weight: 600;">Your Invoices</div>

      <div class="receipts-table">
        <table class="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Price</th>
            <th>Invoice Link</th>
            <th>Receipt Link</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id" v-show="invoice.total > 0">
            <td>{{ invoice.created | day }}</td>
            <td>${{ invoice.total }}</td>
            <td>
              <a class="BaseButton normal primary is-outlined" style="width:166px" :href="invoice.invoice_pdf">Download Invoice</a>
            </td>
            <td>
              <a class="BaseButton normal primary is-outlined" style="width:166px" :href="invoice.receipt_pdf">Download Receipt</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import subscriptionsApi from '@/api/subscriptionsApi'
import BaseLoaderDualRing from '@/components/Base/BaseLoaderDualRing.vue'

export default {
  components: {
    BaseLoaderDualRing
  },

  data () {
    return {
      invoices: [],
      loading: false
    }
  },

  created () {
    this.getInvoices()
  },

  methods: {
    async getInvoices () {
      this.loading = true
      try {
        const response = await subscriptionsApi.getInvoices()
        this.invoices = response.data.data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  },
  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>
